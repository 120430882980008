const lightGradients = [
  'from-pink-300 via-purple-400 to-rose-500',
  'from-pink-400 to-purple-600',
  'from-rose-500 via-pink-500 to-purple-600',
]

const darkGradients = ['from-pink-700 to-purple-900', 'from-rose-700 to-purple-900']

const gradientDirections = ['bg-gradient-to-br', 'bg-gradient-to-bl', 'bg-gradient-to-tr', 'bg-gradient-to-tl']

export const getRandomGradient = (isDark = false) => {
  const gradients = isDark ? darkGradients : lightGradients
  const index = getRandomNumber(0, gradients.length - 1)
  return gradients[index]
}

export const getRandomGradientDirection = () => {
  const index = getRandomNumber(0, gradientDirections.length - 1)
  return gradientDirections[index]
}

export const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
